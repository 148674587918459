<template>
  <v-container>
    <div>
      <!-- Prima riga -->
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-select
            v-model="requestType"
            :items="requestTypeOptions"
            :label="$t('TicketingTable.requestType')"
            multiple
            chips
            small-chips
            hide-details
            prepend-icon="mdi-file-cog-outline"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-select
            v-model="requestState"
            :items="requestStateOptions"
            :label="$t('TicketingTable.requestState')"
            multiple
            chips
            small-chips
            hide-details
            prepend-icon="mdi-checkbox-marked-circle-outline"
          />
        </v-col>
      </v-row>

      <!-- Seconda riga -->
      <v-row>
        <v-col
          cols="12"
          sm="4"
        >
          <v-menu
            ref="rangeMenu"
            v-model="rangeMenu"
            :close-on-content-click="false"
            :return-value.sync="rangeDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="rangeDateText"
                multiple
                chips
                small-chips
                :label="`${$t('Ticketing.rangeDate')} *`"
                prepend-icon="mdi-calendar-range"
                readonly
                hide-details
                v-bind="attrs"
                class="shrink"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="rangeDate"
              no-title
              range
              scrollable
              :min="rangeDate[0]"
              :max="moment().format('YYYY-MM-DD')"
            >
              <v-btn
                text
                color="primary"
                @click="closeDateRange()"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                :disabled="rangeDate.length < 2"
                @click="changeFilterDate()"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col
          cols="12"
          sm="4"
        >
          <v-menu
            ref="evasionExpectedMenu"
            v-model="evasionExpectedMenu"
            :close-on-content-click="false"
            :return-value.sync="evasionExpected"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="evasionExpectedText"
                multiple
                chips
                small-chips
                :label="$t('TicketingTable.evasionLimit')"
                prepend-icon="mdi-calendar-clock"
                readonly
                hide-details
                v-bind="attrs"
                class="shrink"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="evasionExpected"
              no-title
              range
              scrollable
              :min="evasionExpected[0]"
            >
              <v-btn
                text
                color="primary"
                @click="closeEvasionExpectedDate()"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                :disabled="evasionExpected.length < 2"
                @click="saveEvasionExpectedDate()"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-menu
            ref="evasionDateMenu"
            v-model="evasionDateMenu"
            :close-on-content-click="false"
            :return-value.sync="evasionDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="evasionDateText"
                multiple
                chips
                small-chips
                :label="$t('TicketingTable.evasionDate')"
                prepend-icon="mdi-calendar-check"
                readonly
                hide-details
                v-bind="attrs"
                class="shrink"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="evasionDate"
              no-title
              range
              scrollable
              :min="evasionDate[0]"
              :max="moment().format('YYYY-MM-DD')"
            >
              <v-btn
                text
                color="primary"
                @click="closeEvasionDate()"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                :disabled="evasionDate.length < 2"
                @click="saveEvasionDate()"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <!-- Terza riga -->
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-select
            v-model="documentType"
            :items="documentTypesList"
            :label="$t('Ticketing.documentType')"
            multiple
            chips
            small-chips
            clearable
            :loading="isLoading"
            hide-no-data
            hide-details
            prepend-icon="mdi-file-document-multiple-outline"
            @click="getList('document')"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-select
            v-model="meteoType"
            :items="meteoTypesList"
            :label="$t('Ticketing.meteoType')"
            multiple
            chips
            small-chips
            clearable
            :loading="isLoading"
            hide-no-data
            hide-details
            prepend-icon="mdi-weather-cloudy"
            @click="getList('meteo')"
          />
        </v-col>
      </v-row>

      <!-- Search requests -->
      <v-alert
        v-if="alert"
        dense
        text
        type="warning"
        class="mt-3 mx-5"
        icon="mdi-alert-outline"
        transition="scale-transition"
      >
        {{ alert }}
      </v-alert>
      <v-row>
        <v-col
          class="text-right"
          cols="12"
        >
          <v-btn
            color="RMprimary"
            :dark="searchIsEnabled"
            :disabled="!searchIsEnabled"
            @click="confirmSearchFilters()"
          >
            <v-icon class="me-3">
              mdi-magnify
            </v-icon>
            {{ $t('AlertExPost.search') }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import moment from 'moment-business-days';
import { mapActions, mapState } from 'vuex';
import { requestStates } from '@/constants.js';

export default {
  data() {
    return {
      isLoading: false,
      rangeMenu: false,
      evasionExpectedMenu: false,
      evasionDateMenu: false,
      rangeDate: [],
      evasionExpected: [],
      evasionDate: [],
      documentType: [],
      documentTypesList: [],
      meteoType: [],
      meteoTypesList: [],
      requestType: ['A', 'AM', 'M'],
      requestTypeOptions: ['A', 'AM', 'M'],
      requestState: ['SENT', 'TAKING CHARGE', 'EMITTED'],
      alert: ''
    };
  },
  computed: {
    ...mapState(['ticketing', 'policy']),
    requestStateOptions () {
      return requestStates;
    },
    searchIsEnabled() {
      return this.rangeDate.length > 1;
    },
    rangeDateText() {
      const start_date = this.rangeDate[0] ? `${moment(this.rangeDate[0]).format('DD-MM-YYYY')},` : '';
      const end_date = this.rangeDate[1] ? `${moment(this.rangeDate[1]).format('DD-MM-YYYY')}` : '';

      return `${start_date} ${end_date}`;
    },
    evasionExpectedText() {
      const start_date = this.evasionExpected[0] ? `${moment(this.evasionExpected[0]).format('DD-MM-YYYY')},` : '';
      const end_date = this.evasionExpected[1] ? `${moment(this.evasionExpected[1]).format('DD-MM-YYYY')}` : '';

      return `${start_date} ${end_date}`;
    },
    evasionDateText() {
      const start_date = this.evasionDate[0] ? `${moment(this.evasionDate[0]).format('DD-MM-YYYY')},` : '';
      const end_date = this.evasionDate[1] ? `${moment(this.evasionDate[1]).format('DD-MM-YYYY')}` : '';

      return `${start_date} ${end_date}`;
    }
  },
  async mounted() {
    this.getList('document');
    this.getList('meteo');

    const sixMonthsAgo = moment().subtract(6, 'months').startOf('day').format('YYYY-MM-DD');
    const today = moment().endOf('day').format('YYYY-MM-DD');

    if (Object.keys(this.ticketing.searchFilters).length) {
      this.rangeDate = this.ticketing.searchFilters.rangeDate;
      this.evasionExpected = this.ticketing.searchFilters.evasionExpected;
      this.evasionDate = this.ticketing.searchFilters.evasionDate;
      this.documentType = this.ticketing.searchFilters.documentType;
      this.meteoType = this.ticketing.searchFilters.meteoType;
      this.requestType = this.ticketing.searchFilters.requestType;
      this.requestState = this.ticketing.searchFilters.requestState;
    } else {
      this.rangeDate = [sixMonthsAgo, today];
    }

    await this.confirmSearchFilters();
  },
  methods: {
    ...mapActions(['listDocumentMeteoTypesAction', 'storeGetTicketingRequest']),
    async getList(type) {
      this.isLoading = true;
      await this.listDocumentMeteoTypesAction(type);

      if (this.ticketing.documentTypes?.length > 0) {
        this.documentTypesList = JSON.parse(JSON.stringify(this.ticketing.documentTypes));
      }

      if (this.ticketing.meteoTypes?.length > 0) {
        this.meteoTypesList = JSON.parse(JSON.stringify(this.ticketing.meteoTypes));
      }

      this.isLoading = false;
    },
    changeFilterDate() {
      this.$refs.rangeMenu.save(this.rangeDate);
    },
    saveEvasionExpectedDate() {
      this.$refs.evasionExpectedMenu.save(this.evasionExpected);
    },
    saveEvasionDate() {
      this.$refs.evasionDateMenu.save(this.evasionDate);
    },
    closeDateRange() {
      this.rangeMenu = false;
      this.$refs.rangeMenu.save([]);
    },
    closeEvasionExpectedDate() {
      this.evasionExpectedMenu = false;
      this.$refs.evasionExpectedMenu.save([]);
    },
    closeEvasionDate() {
      this.evasionDateMenu = false;
      this.$refs.evasionDateMenu.save([]);
    },
    // resetFilters() {
    //   this.rangeDate = [];
    //   this.evasionExpected = [];
    //   this.evasionDate = [];
    //   this.documentType = [];
    //   this.meteoType = [];
    //   this.requestType = ['A', 'AM', 'M'];
    //   this.requestState = ['EMITTED'];
    //   this.latitude = '';
    //   this.longitude = '';
    //   this.radius = '0.3';
    //   this.alert = '';
    // },
    async confirmSearchFilters() {
      this.alert = '';
      const requestData = {
        requestType: this.requestType,
        requestState: this.requestState,
        rangeDate: this.rangeDate,
        evasionExpected: this.evasionExpected,
        evasionDate: this.evasionDate,
        documentType: this.documentType,
        meteoType: this.meteoType
      };

      this.$emit('is-loading', true);
      this.ticketing.searchFilters = {
        ...requestData
      };
      await this.storeGetTicketingRequest(this.policy.ticketingServicePermissions);

      if (this.ticketing.ticketingRequestListFormatted.length) {
        this.$emit('show-results', true);
      } else {
        this.alert = this.$t('TicketingTable.noResults');
      }

      this.$emit('is-loading', false);
    }
  }
};
</script>
